import { useEffect, useRef, useState } from 'react';
import {
  NavLink,
  NavLinkSections,
  NavMetaDataType,
  NavLinkSectionsValidator,
} from 'types';
import {
  GetNavLinkSectionsRequest,
  GetNavLinkSectionsResponse,
  SetNavLinkSectionsRequest,
  SetNavLinkSectionsResponse,
} from './actions';

type UseNavManagerType = {
  links: Array<NavLink>;
  metaData: NavMetaDataType;
};

export const useNavManager = (
  initialLinks: Array<NavLink>,
): UseNavManagerType => {
  const [links, setLinks] = useState(initialLinks);
  const [metaData, setMetaData] = useState<NavMetaDataType>({
    hideMainNavBar: false,
    hideSideNavBar: false,
  });
  const listeners = useRef<((ev: MessageEvent) => void)[]>([]);

  useEffect(() => {
    listeners.current.push((event: MessageEvent<GetNavLinkSectionsRequest>) => {
      if (
        event.data.action !== 'getNavLinkSections' ||
        event.origin !== window.location.origin
      ) {
        return;
      }

      const siteObj = links.find(l => l.label === event.data.site);

      const data: NavLinkSections = {
        externalSections: siteObj?.externalSections,
        internalSections: siteObj?.internalSections,
        jumpToSections: siteObj?.jumpToSections,
        menuSections: siteObj?.menuSections,
      };

      const message: GetNavLinkSectionsResponse = {
        messageId: event.data.messageId,
        action: 'getNavLinkSectionsResponse',
        data: data,
      };

      event.source?.postMessage(message);
    });

    //Set Nav Link Sections
    listeners.current.push((event: MessageEvent<SetNavLinkSectionsRequest>) => {
      if (
        event.data.action !== 'setNavLinkSections' ||
        event.origin !== window.location.origin
      ) {
        return;
      }
      setMetaData({
        hideMainNavBar: event?.data.parameters.metaData?.hideMainNavBar,
        hideSideNavBar: event?.data.parameters.metaData?.hideSideNavBar,
      });
      const siteIndex = links.findIndex(l => l.label === event.data.site);
      let isSuccessful = siteIndex !== -1;
      if (isSuccessful) {
        try {
          NavLinkSectionsValidator.validate(event.data.parameters);

          const newLinks = [...links];

          newLinks[siteIndex] = {
            ...newLinks[siteIndex],
            ...event.data.parameters,
          };

          setLinks(newLinks);
        } catch (e) {
          isSuccessful = false;
          console.error(
            'main-nav SetNavLinkSectionsRequest: parameters likely in incorrect format -->',
            e,
          );
        }
      } else {
        console.error(
          `main-nav SetNavLinkSectionsRequest: could not find Site with label: [${event.data.site}]`,
        );
      }

      const message: SetNavLinkSectionsResponse = {
        messageId: event.data.messageId,
        action: 'setNavLinkSectionsResponse',
        data: {
          isSuccessful: isSuccessful,
        },
      };

      event.source?.postMessage(message);
    });

    //register listeners
    for (const listener of listeners.current) {
      window.addEventListener('message', listener);
    }

    return () => {
      //unregister listeners (also happens on listener updates)
      for (let i = listeners.current.length - 1; i >= 0; i--) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.removeEventListener('message', listeners.current[i]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        listeners.current.splice(i, 1);
      }
    };
  }, [links]);

  return { links, metaData };
};

export default useNavManager;
