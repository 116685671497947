import AboutSvg from 'components/svgs/about-svg';
import BrandSvg from 'components/svgs/brand-svg';
import FinanceSvg from 'components/svgs/finance-svg';
import IntelligenceSvg from 'components/svgs/intelligence-svg';
import NotificationsSvg from 'components/svgs/notifications-svg';
import PeopleSvg from 'components/svgs/people-svg';
import PipelineSvg from 'components/svgs/pipeline-svg';
import React from 'react';
import HomeSvg from '../components/svgs/home-svg';
import PrioritizedAgendaSvg from '../components/svgs/prioritizedAgenda-svg';
import SupplySvg from '../components/svgs/supply-svg';
import ShieldSvg from '../components/svgs/shield-svg';

const getIcon = (id?: string) => {
  switch (id) {
    case 'home':
      return <HomeSvg />;
    case 'prioritizedAgenda':
      return <PrioritizedAgendaSvg />;
    case 'people':
      return <PeopleSvg />;
    case 'brand':
      return <BrandSvg />;
    case 'supply':
      return <SupplySvg />;
    case 'pipeline':
      return <PipelineSvg />;
    case 'finance':
      return <FinanceSvg />;
    case 'external':
      return <IntelligenceSvg />;
    case 'notificationsSubscriptions':
      return <NotificationsSvg />;
    case 'about':
      return <AboutSvg />;
    case 'admin':
      return <ShieldSvg />;
    case 'horizon':
      return null;
    default:
      return null;
  }
};

export default getIcon;
