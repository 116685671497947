import React from 'react';

type SvgProps = {
  className?: string;
};

const FinanceSvg = (props: SvgProps) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 10.6667C4.93188 10.6667 5.18624 10.5613 5.37377 10.3738C5.56131 10.1863 5.66667 9.93192 5.66667 9.66671C5.66992 9.63345 5.66992 9.59996 5.66667 9.56671L7.52667 7.70671H7.68H7.83333L8.90667 8.78004C8.90667 8.78004 8.90667 8.81337 8.90667 8.83337C8.90667 9.09859 9.01202 9.35294 9.19956 9.54048C9.3871 9.72802 9.64145 9.83337 9.90667 9.83337C10.1719 9.83337 10.4262 9.72802 10.6138 9.54048C10.8013 9.35294 10.9067 9.09859 10.9067 8.83337V8.78004L13.3333 6.33337C13.5311 6.33337 13.7245 6.27472 13.8889 6.16484C14.0534 6.05496 14.1815 5.89878 14.2572 5.71606C14.3329 5.53333 14.3527 5.33226 14.3141 5.13828C14.2755 4.9443 14.1803 4.76612 14.0404 4.62627C13.9006 4.48641 13.7224 4.39117 13.5284 4.35259C13.3344 4.314 13.1334 4.33381 12.9507 4.40949C12.7679 4.48518 12.6117 4.61335 12.5019 4.7778C12.392 4.94225 12.3333 5.13559 12.3333 5.33337C12.3301 5.36663 12.3301 5.40012 12.3333 5.43337L9.92667 7.84004H9.82L8.66667 6.66671C8.66667 6.40149 8.56131 6.14714 8.37377 5.9596C8.18624 5.77206 7.93188 5.66671 7.66667 5.66671C7.40145 5.66671 7.1471 5.77206 6.95956 5.9596C6.77202 6.14714 6.66667 6.40149 6.66667 6.66671L4.66667 8.66671C4.40145 8.66671 4.1471 8.77206 3.95956 8.9596C3.77202 9.14714 3.66667 9.40149 3.66667 9.66671C3.66667 9.93192 3.77202 10.1863 3.95956 10.3738C4.1471 10.5613 4.40145 10.6667 4.66667 10.6667ZM13.6667 13.3334H2.33333V2.00004C2.33333 1.82323 2.2631 1.65366 2.13807 1.52864C2.01305 1.40361 1.84348 1.33337 1.66667 1.33337C1.48986 1.33337 1.32029 1.40361 1.19526 1.52864C1.07024 1.65366 1 1.82323 1 2.00004V14C1 14.1769 1.07024 14.3464 1.19526 14.4714C1.32029 14.5965 1.48986 14.6667 1.66667 14.6667H13.6667C13.8435 14.6667 14.013 14.5965 14.1381 14.4714C14.2631 14.3464 14.3333 14.1769 14.3333 14C14.3333 13.8232 14.2631 13.6537 14.1381 13.5286C14.013 13.4036 13.8435 13.3334 13.6667 13.3334Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FinanceSvg;
