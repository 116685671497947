import React from 'react';
import './index.scss';

type Props = {
  className?: string;
  isActive?: boolean;
  children?: React.ReactNode;
  forwardRef?: React.MutableRefObject<null>;
  onMouseLeave?: React.MouseEventHandler;
};

const MegaMenu = ({
  className = '',
  isActive = false,
  children,
  forwardRef,
  onMouseLeave,
}: Props) => {
  const classNames = ['mega-menu', isActive && 'is-active', className].join(
    ' ',
  );
  return (
    <div className={classNames} ref={forwardRef} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  );
};

export default MegaMenu;
