export function toCamelCase(sentence: string) {
  return sentence
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      } else {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join('');
}

export function caseInsensitiveMatch(str1: string, str2: string): boolean {
  return str1.toLowerCase() === str2.toLowerCase();
}

export function getEnv(): string {
  const envSiteId = [
    { dev: 34 },
    { test: 35 },
    { stg: 36 },
    { prod: 37 },
    { localhost: 34 },
    { "sensing.nimbus.amgen.com": 37 },
    { "sensing.amgen.com": 37 }
  ];
  const currEnvObj = envSiteId.filter(
    siteEnvObj => window.location.host.indexOf(Object.keys(siteEnvObj)[0]) > -1,
  );

  let env = '';
  if (Object.keys(currEnvObj[0])[0] == "sensing.nimbus.amgen.com" || Object.keys(currEnvObj[0])[0] == "sensing.amgen.com") {
    env = "prod";
  }
  else {
    env = Object.keys(currEnvObj[0])[0];
  }

  return env;
}
