import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import singleSpaReact from 'single-spa-react';
import 'index.scss';
export { NavManager } from 'nav-manager';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(err, info, props) {
    return (
      <div>This renders when a catastrophic error occurs -- test 302 #1001</div>
    );
  },
});
export const bootstrap = reactLifecycles.bootstrap;
export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
