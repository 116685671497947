import React, { useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  Menu,
  NavigationMenuGroup,
  NavigationMenuItem,
  NavigationMenuSection,
  ThemeContext,
} from '@opsdti-global-component-library/amgen-design-system';
import { IMegaMenuSection } from 'mega-menu-manager';
import getIcon from 'utils/get-icon';
import { Contents } from 'main-nav';
import { NavLink } from 'types';
import { useStyles } from './styles';

interface MobileLeftNavProps {
  activeRouteData: NavLink;
  handleMenuActive: (menuActive: boolean) => void;
  menuActive: boolean;
  routeData: Array<NavLink>;
  megaMenuContents?: Contents;
  currentMegaMenu?: string;
}

const MobileLeftNav = ({
  handleMenuActive,
  menuActive,
  routeData,
  megaMenuContents,
}: MobileLeftNavProps) => {
  const { theme } = useContext(ThemeContext);
  const hoverBgColor = theme?.token?.colorPrimaryBg;
  const hoverTextColor = theme?.token?.colorPrimary;

  const { cx, classes } = useStyles({
    hoverBgColor,
    hoverTextColor,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const defaultSelectedKey = pathname.split('/').filter(Boolean)[0];

  const getLinks = useCallback(() => {
    const navLinks: (NavigationMenuItem | NavigationMenuSection)[] = [];

    routeData.forEach((link, index) => {
      const linkAuthorized = link.authorized;
      if (linkAuthorized) {
        const hasContents =
          megaMenuContents &&
          Object.keys(megaMenuContents).map(key => key).length > 0;

        const type =
          hasContents &&
          Object.keys(megaMenuContents)
            ?.map(key => key)
            ?.find(key => key === link.id)
            ? ('section' as const)
            : ('item' as const);

        let linkItem: NavigationMenuItem | NavigationMenuSection;

        // this section of the logic is for top level links without sublinks
        if (type === 'item') {
          linkItem = {
            children: undefined,
            icon: getIcon(link.id),
            key: link?.id || index.toString(),
            label: link.label,
            onClick: () => {
              handleMenuActive(false);
              link.url && navigate(link.url);
            },
            type: 'item' as const,
          };
          navLinks.push(linkItem);
        }

        if (type === 'section') {
          let sectionChildren: NavigationMenuGroup[] = [];

          const key =
            hasContents &&
            (Object.keys(megaMenuContents)
              ?.map(key => key)
              ?.find(key => key === link.id) as keyof Contents);
          const content: IMegaMenuSection[] = key
            ? megaMenuContents?.[key]
            : [];

          sectionChildren = content?.map((section, idx) => {
            const hasTitle = section.sectionLabel.trim().length > 0;
            const label = hasTitle ? section.sectionLabel : undefined;

            const children = section.links.map((link, link_idx) => ({
              type: 'item' as const,
              key: `item_${link.url}-${index}__section-${idx}__item-${link_idx}`,
              label: link.label,
              onClick: () => {
                handleMenuActive(false);
                link.url && navigate(link.url);
              },
            }));

            return {
              children,
              key: `item_${link.url}-${index}__section-${idx}`,
              label,
              type: 'group' as const,
            };
          });

          linkItem = {
            children: sectionChildren,
            icon: getIcon(link.id),
            key: `item_${link.url}-${index}`,
            label: link.label,
            onClick: () => {
              handleMenuActive(false);
              link.url && navigate(link.url);
            },
            type: 'section' as const,
          };
          navLinks.push(linkItem);
        }
      }
    });

    return navLinks;
  }, [handleMenuActive, megaMenuContents, navigate, routeData]);

  const links = getLinks();
  const drawerStyles = {
    header: { display: 'none' },
    body: { padding: '8px 0' },
  };

  return (
    <Drawer
      styles={drawerStyles}
      placement="left"
      open={menuActive}
      className={cx(classes.mobileLeftNav)}
      onClose={() => handleMenuActive(false)}
      {...{ defaultSelectedKey, rootStyle: { top: 64 } }}
    >
      <Menu items={links} />
    </Drawer>
  );
};

export default MobileLeftNav;
