import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Link as DSLink } from '@opsdti-global-component-library/amgen-design-system';
import type { To } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  to: To;
};

const Link = ({ children, to, ...rest }: Props) => {
  return (
    <RRLink to={to}>
      <DSLink {...rest}>{children}</DSLink>
    </RRLink>
  );
};

export default Link;
