import axios from 'axios';
import { ExternalMegaMenuUtility } from '@amgen/rtsensing-external-utility-module';
import { getOktaDetails, getUsernameFromToken } from 'utils/auth-utils';
import { getEnv } from 'utils';

export interface IMegaMenuSection {
  sectionLabel: string;
  links: {
    label: string;
    url: string;
    isDisabled?: boolean;
  }[];
}

interface INestedBooleanObjectMap {
  [key: string]: boolean | INestedBooleanObjectMap;
}

interface INavigationInstance {
  getAuthorizationQuery(): string;
  getAuthorizationQueryKeys(): string[];
  generateMegaMenuSections(
    response: INestedBooleanObjectMap,
  ): IMegaMenuSection[];
  workstream: string;
}

const ENDPOINTS = {
  localhost:
    'https://authorization-api-dev.nimbus.amgen.com/authorization/graphql',
  dev: 'https://authorization-api-dev.nimbus.amgen.com/authorization/graphql',
  test: 'https://authorization-api-test.nimbus.amgen.com/authorization/graphql',
  stg: 'https://authorization-api-stg.nimbus.amgen.com/authorization/graphql',
  prod: 'https://authorization-api.nimbus.amgen.com/authorization/graphql',
};

export class MegaMenuManager {
  private endpoint: string;
  private navInstancesMap: { [key: string]: INavigationInstance };
  private workstreams: string[];

  constructor(permissions: INestedBooleanObjectMap) {
    this.endpoint = ENDPOINTS[getEnv() as keyof typeof ENDPOINTS];
    const { navInstancesMap, workstreams } =
      this.initializeNavigationInstancesFromPermissions(permissions);
    this.navInstancesMap = navInstancesMap;
    this.workstreams = workstreams;
  }

  private initializeNavigationInstancesFromPermissions(
    permissions: INestedBooleanObjectMap,
  ) {
    const navInstancesMap: { [key: string]: INavigationInstance } = {};
    const workstreams = [];
    if (ExternalMegaMenuUtility !== undefined) {
      navInstancesMap['external'] = new ExternalMegaMenuUtility();
    }
    /* repeat for each navigation instance */

    for (const [key, value] of Object.entries(permissions)) {
      if (typeof value === 'boolean' && navInstancesMap[key]) {
        if (!value) {
          delete navInstancesMap[key];
        } else {
          workstreams.push(key);
        }
      }
    }
    return { navInstancesMap, workstreams };
  }

  private constructAuthorizationQuery(): string {
    const username = getUsernameFromToken();
    const workstreams = this.workstreams
      .map(workstream => `"${workstream}"`)
      .join(', ');
    const sensingAuthorization = this.workstreams
      .map(workstream =>
        this.navInstancesMap[workstream].getAuthorizationQuery(),
      )
      .join('\n');
    return `query {
      sensingAuthorization(username:"${username}", workstreams:[${workstreams}], skipWorkstreamLevelAuth: false){
        ${sensingAuthorization}
      }
    }`;
  }

  private extractMenuSectionsFromResponse(response: any): {
    [key: string]: IMegaMenuSection[];
  } {
    return this.workstreams.reduce((menuSections, workstream) => {
      const targetKeys =
        this.navInstancesMap[workstream]?.getAuthorizationQueryKeys();
      const authSection = targetKeys?.reduce((section, key) => {
        if (response[key]) {
          // eslint-disable-next-line no-param-reassign
          section[key] = response[key];
        }
        return section;
      }, {} as any);
      if (authSection) {
        // eslint-disable-next-line no-param-reassign
        menuSections[workstream] =
          this.navInstancesMap[workstream]?.generateMegaMenuSections(
            authSection,
          );
      }
      return menuSections;
    }, {} as { [key: string]: IMegaMenuSection[] });
  }

  private async fetchAuthorizationData(query: string): Promise<any> {
    const { accessToken } = getOktaDetails().accessToken;
    try {
      const response = await axios.post(
        this.endpoint,
        { query },
        {
          headers: {
            ContentType: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.data.errors) {
        console.error('GraphQL errors:', response.data.errors);
        throw new Error('GraphQL errors occurred');
      }

      return response.data.data.sensingAuthorization;
    } catch (error) {
      console.error('Failed to fetch mega menu data:', error);
      throw error;
    }
  }

  public async generateMenuSections(): Promise<{
    [key: string]: IMegaMenuSection[];
  }> {
    if (this.workstreams.length === 0) {
      return {};
    }

    const consolidatedQuery = this.constructAuthorizationQuery();
    const sensingAuthorization = await this.fetchAuthorizationData(
      consolidatedQuery,
    );
    return this.extractMenuSectionsFromResponse(sensingAuthorization);
  }
}
