import React from 'react';

interface SvgProps {
  className?: string;
}

const ShieldSvg = (props: SvgProps) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9476 2.43333C13.8701 2.37068 13.7796 2.32618 13.6827 2.30311C13.5858 2.28003 13.485 2.27897 13.3876 2.3C12.6752 2.4493 11.9398 2.4512 11.2267 2.30559C10.5135 2.15998 9.83775 1.86994 9.24092 1.45333C9.12933 1.37592 8.99675 1.33443 8.86093 1.33443C8.7251 1.33443 8.59252 1.37592 8.48093 1.45333C7.8841 1.86994 7.20831 2.15998 6.49517 2.30559C5.78203 2.4512 5.04663 2.4493 4.33426 2.3C4.23688 2.27897 4.13604 2.28003 4.03913 2.30311C3.94222 2.32618 3.85171 2.37068 3.77426 2.43333C3.69691 2.49608 3.63461 2.57536 3.59192 2.66534C3.54923 2.75533 3.52725 2.85374 3.52759 2.95333V7.92C3.527 8.87583 3.75477 9.81798 4.19195 10.668C4.62912 11.518 5.26304 12.2512 6.04093 12.8067L8.47426 14.54C8.58717 14.6204 8.72232 14.6636 8.86093 14.6636C8.99953 14.6636 9.13468 14.6204 9.24759 14.54L11.6809 12.8067C12.4588 12.2512 13.0927 11.518 13.5299 10.668C13.9671 9.81798 14.1949 8.87583 14.1943 7.92V2.95333C14.1946 2.85374 14.1726 2.75533 14.1299 2.66534C14.0872 2.57536 14.0249 2.49608 13.9476 2.43333ZM12.8609 7.92C12.8614 8.66317 12.6844 9.39572 12.3447 10.0567C12.0049 10.7176 11.5122 11.2879 10.9076 11.72L8.86093 13.18L6.81426 11.72C6.20962 11.2879 5.71691 10.7176 5.37716 10.0567C5.0374 9.39572 4.86041 8.66317 4.86093 7.92V3.72C6.25854 3.83962 7.65829 3.51535 8.86093 2.79333C10.0636 3.51535 11.4633 3.83962 12.8609 3.72V7.92Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ShieldSvg;
