import React from 'react';
import { Flex } from '@opsdti-global-component-library/amgen-design-system';
import InternalSection from './internal-section';
import { Contents } from 'main-nav';
import './index.scss';

type MegaMenuContentsProps = {
  data?: Contents;
  currentMegaMenu?: string;
  handleClose: () => void;
};

const MegaMenuContents = ({
  data,
  currentMegaMenu,
  handleClose,
}: MegaMenuContentsProps) => {
  if (currentMegaMenu && data) {
    const contents = data[currentMegaMenu];
    return (
      <div className="mega-menu-content">
        <Flex gap={16} align="start">
          {contents?.map((section, idx: number) => (
            <InternalSection key={idx} {...{ section, handleClose }} />
          ))}
        </Flex>
      </div>
    );
  }
  return <></>;
};

export default MegaMenuContents;
