import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { NavLink, NavSection } from 'types';
import { ChevronIcon } from 'icons';
import { FadeTransition } from 'components/fade-transition';
import SectionLink from './section-link';
import SectionTitle from './section-title';
import './index.scss';
import {
  CaretUpIcon,
  CaretDownIcon,
  Button,
} from '@opsdti-global-component-library/amgen-design-system';

interface DesktopSideNavProps {
  activeRouteData: NavLink;
  hideNav: boolean;
}

export const UPDATE_SIDE_NAV_WORKSTREAMS = [
  'INTELLIGENCE',
  'PRIORITIZED AGENDA',
];

export const DesktopSideNav = (props: DesktopSideNavProps) => {
  const { activeRouteData, hideNav } = props;
  const [opened, setOpened] = useState(() => window.innerWidth >= 1400);
  const [menuSectionsVisibility, setMenuSectionsVisibility] = useState<
    Record<string, boolean>
  >({});
  const navRoot = document.getElementById('sensing-desktop-side-nav');
  const contentRoot = document.getElementById('sensing-main-content');

  const isUpdatedSidenavWorkstream = UPDATE_SIDE_NAV_WORKSTREAMS.includes(
    activeRouteData.label.toUpperCase(),
  );

  useEffect(() => {
    if (navRoot && contentRoot) {
      if (opened) {
        navRoot.classList.add('nav-active');
        contentRoot.classList.add('nav-active');
      } else {
        navRoot.classList.remove('nav-active');
        contentRoot.classList.remove('nav-active');
      }
    }
  }, [opened, navRoot, contentRoot]);

  useEffect(() => {
    if (hideNav) {
      if (navRoot && contentRoot) {
        navRoot.classList.add('sensing-nav-hide');
        navRoot.classList.remove('nav-active');
        contentRoot.classList.remove('nav-active');
      }
    } else {
      if (navRoot) {
        navRoot.classList.remove('sensing-nav-hide');
      }
    }
  }, [hideNav, navRoot, contentRoot]);

  const toggleNav = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    if (activeRouteData.menuSections) {
      const visibility = activeRouteData.menuSections.reduce(
        (acc, menuSection) => {
          // eslint-disable-next-line no-param-reassign
          acc[menuSection.label] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );
      setMenuSectionsVisibility(visibility);
    } else {
      setMenuSectionsVisibility({
        internalSections: true,
        externalSections: true,
        jumpToSections: true,
      });
    }
  }, [activeRouteData]);

  const renderSubLinks = (
    sectionData: Array<NavSection>,
    linkType: 'internal' | 'external' | 'internalSection',
  ) => {
    const subLinkSections: Array<JSX.Element> = [];
    if (sectionData.length) {
      sectionData.forEach(section => {
        subLinkSections.push(
          <SectionTitle
            key={section.sectionLabel}
            section={section}
            pageName={activeRouteData.label}
          />,
        );

        if (section.links && section.links.length) {
          section.links.forEach((link, index) => {
            if (link.authorized !== false) {
              subLinkSections.push(
                <SectionLink
                  key={link.label + link.url + index}
                  type={linkType}
                  url={link.url ?? '#'}
                  disabled={link.disabled}
                  submenu={link.subMenu}
                  pageName={activeRouteData.label}
                >
                  {link.label}
                </SectionLink>,
              );
            }
          });
        }
      });
    }

    return subLinkSections;
  };

  if (!navRoot) {
    return null;
  }

  const NavHeader = ({
    label,
    opened,
    toggleNav,
    isUpdatedSidenavWorkstream,
  }: {
    label: string;
    opened: boolean;
    toggleNav: () => void;
    isUpdatedSidenavWorkstream: boolean;
  }) => (
    <div className="nav-header-block">
      <FadeTransition itemKey={label}>
        <p className="nav-header-title">
          {isUpdatedSidenavWorkstream ? label : label.toUpperCase()}
        </p>
      </FadeTransition>
      <button
        className={`sensing-nav-side-toggle-button ${
          label.toUpperCase() === 'INTELLIGENCE' ? 'intelligence-nav-btn' : ''
        } ${opened ? 'sensing-nav-side-toggle-button--nav-active' : ''}`}
        onClick={toggleNav}
      >
        <ChevronIcon direction={`${opened ? 'LEFT' : 'RIGHT'}`} />
      </button>
    </div>
  );

  const NavLinksBlock = ({
    sections,
    linkType,
    showLinks,
    toggleShowLinks,
    title,
    isUpdatedSidenavWorkstream,
  }: {
    sections?: Array<NavSection>;
    linkType: 'internal' | 'external' | 'internalSection';
    showLinks: boolean;
    toggleShowLinks: () => void;
    title?: string;
    isUpdatedSidenavWorkstream: boolean;
  }) => {
    if (!sections?.length) return null;

    const className = isUpdatedSidenavWorkstream
      ? 'intelligence-links-block'
      : 'nav-sub-links-block';

    return (
      <div className={className}>
        {isUpdatedSidenavWorkstream && title && (
          <div className="quick-link-block">
            <p className="quick-link-title">{title}</p>
            <Button
              className=""
              type="link"
              icon={
                showLinks ? (
                  <CaretUpIcon height={24} width={24} color="black" />
                ) : (
                  <CaretDownIcon height={24} width={24} color="black" />
                )
              }
              onClick={toggleShowLinks}
            />
          </div>
        )}
        {(!isUpdatedSidenavWorkstream || showLinks) &&
          renderSubLinks(sections, linkType)}
      </div>
    );
  };

  return createPortal(
    <div>
      {activeRouteData.url && (
        <>
          <NavHeader
            label={activeRouteData.label}
            opened={opened}
            toggleNav={toggleNav}
            isUpdatedSidenavWorkstream={isUpdatedSidenavWorkstream}
          />
          <FadeTransition itemKey={activeRouteData.label}>
            <div className="link-container">
              {activeRouteData.menuSections ? (
                activeRouteData.menuSections.map(menuSection => (
                  <NavLinksBlock
                    key={menuSection.label}
                    sections={menuSection.sections}
                    linkType={menuSection.sectionType}
                    showLinks={menuSectionsVisibility[menuSection.label]}
                    toggleShowLinks={() =>
                      setMenuSectionsVisibility(prev => ({
                        ...prev,
                        [menuSection.label]: !prev[menuSection.label],
                      }))
                    }
                    title={
                      isUpdatedSidenavWorkstream ? menuSection.label : undefined
                    }
                    isUpdatedSidenavWorkstream={isUpdatedSidenavWorkstream}
                  />
                ))
              ) : (
                <>
                  {activeRouteData.jumpToSections?.length ? (
                    <NavLinksBlock
                      sections={activeRouteData.jumpToSections}
                      linkType="internalSection"
                      showLinks={menuSectionsVisibility['jumpToSections']}
                      toggleShowLinks={() =>
                        setMenuSectionsVisibility(prev => ({
                          ...prev,
                          jumpToSections: !prev.jumpToSections,
                        }))
                      }
                      isUpdatedSidenavWorkstream={isUpdatedSidenavWorkstream}
                    />
                  ) : null}
                  <NavLinksBlock
                    sections={activeRouteData.internalSections}
                    linkType="internal"
                    showLinks={menuSectionsVisibility['internalSections']}
                    toggleShowLinks={() =>
                      setMenuSectionsVisibility(prev => ({
                        ...prev,
                        internalSections: !prev.internalSections,
                      }))
                    }
                    title={
                      isUpdatedSidenavWorkstream ? 'Quick Links' : undefined
                    }
                    isUpdatedSidenavWorkstream={isUpdatedSidenavWorkstream}
                  />
                  <NavLinksBlock
                    sections={activeRouteData.externalSections}
                    linkType="external"
                    showLinks={menuSectionsVisibility['externalSections']}
                    toggleShowLinks={() =>
                      setMenuSectionsVisibility(prev => ({
                        ...prev,
                        externalSections: !prev.externalSections,
                      }))
                    }
                    title={
                      isUpdatedSidenavWorkstream ? 'Related Links' : undefined
                    }
                    isUpdatedSidenavWorkstream={isUpdatedSidenavWorkstream}
                  />
                </>
              )}
            </div>
          </FadeTransition>
        </>
      )}
    </div>,
    navRoot,
  );
};
