import {
  Array as ArrayRuntype,
  Boolean,
  Lazy,
  Literal,
  Record,
  Runtype,
  Static,
  String,
  Union,
} from 'runtypes';

export const megaMenuKeys = ['external'];

const ConfidentialityValidator = Record({
  label: String,
  type: Union(Literal('internal'), Literal('confidential')),
});
export type Confidentiality = Static<typeof ConfidentialityValidator>;

export type ConfidentialityMap = {
  [key: string]: Confidentiality;
};

const submenuValidator = Record({ id: String.optional(),
  label: String,
  url: String.optional(),
  link: String.optional(),
});

export type NavMetaDataType = {
  hideMainNavBar: boolean | undefined;
  hideSideNavBar: boolean | undefined;
};

const NavMetaDataValidator = Lazy(() =>
  Record({
    hideMainNavBar: Boolean,
    hideSideNavBar: Boolean,
  }),
);

//manual type for NavSection, which must match appropriately, b/c of Lazy()
//see https://github.com/pelotom/runtypes/issues/14 for more examples
export type NavSection = {
  sectionLabel: string;
  links: Array<NavLink>;
};
const NavSectionValidator: Runtype<NavSection> = Lazy(() =>
  Record({
    sectionLabel: String,
    links: ArrayRuntype(NavLinkValidator),
  }),
);

const NavMenuItemValidator = Lazy(() =>
  Record({
    label: String,
    sectionType: Union(
      Literal('internal'),
      Literal('external'),
      Literal('internalSection'),
    ),
    sections: ArrayRuntype(NavSectionValidator),
  }),
);

export type NavMenuItem = Static<typeof NavMenuItemValidator>;

export const NavLinkSectionsValidator = Lazy(() =>
  Record({
    internalSections: ArrayRuntype(NavSectionValidator).optional(),
    jumpToSections: ArrayRuntype(NavSectionValidator).optional(),
    externalSections: ArrayRuntype(NavSectionValidator).optional(),
    menuSections: ArrayRuntype(NavMenuItemValidator).optional(),
    metaData: NavMetaDataValidator.optional(),
  }),
);
export type NavLinkSections = Static<typeof NavLinkSectionsValidator>;

const NavLinkValidator = Lazy(() =>
  NavLinkSectionsValidator.extend({
    id: String.optional(),
    label: String,
    url: String.optional(),
    confidentiality: ConfidentialityValidator.optional(),
    authorized: Boolean.optional(),
    disabled: Boolean.optional(),
    path: String.optional(),
    subMenu: ArrayRuntype(submenuValidator).optional(),
  }),
);

export type NavLink = Static<typeof NavLinkValidator> & { icon?: React.ReactNode };

export type Permission = {
  [key: string]: boolean;
};
