import React from 'react';

interface SvgProps {
  className?: string;
}

const NotificationsSvg = (props: SvgProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 8.78671V6.66671C11.9991 5.72212 11.6639 4.80835 11.0538 4.08722C10.4437 3.36609 9.59812 2.88414 8.66675 2.72671V2.00004C8.66675 1.82323 8.59651 1.65366 8.47149 1.52864C8.34646 1.40361 8.17689 1.33337 8.00008 1.33337C7.82327 1.33337 7.6537 1.40361 7.52868 1.52864C7.40365 1.65366 7.33342 1.82323 7.33342 2.00004V2.72671C6.40204 2.88414 5.55643 3.36609 4.94634 4.08722C4.33625 4.80835 4.00104 5.72212 4.00008 6.66671V8.78671C3.61105 8.92425 3.27408 9.17875 3.03537 9.51533C2.79667 9.85191 2.66791 10.2541 2.66675 10.6667V12C2.66675 12.1769 2.73699 12.3464 2.86201 12.4714C2.98703 12.5965 3.1566 12.6667 3.33341 12.6667H5.42675C5.58027 13.2316 5.91542 13.7303 6.38048 14.0859C6.84554 14.4414 7.41468 14.634 8.00008 14.634C8.58548 14.634 9.15462 14.4414 9.61968 14.0859C10.0847 13.7303 10.4199 13.2316 10.5734 12.6667H12.6667C12.8436 12.6667 13.0131 12.5965 13.1382 12.4714C13.2632 12.3464 13.3334 12.1769 13.3334 12V10.6667C13.3323 10.2541 13.2035 9.85191 12.9648 9.51533C12.7261 9.17875 12.3891 8.92425 12.0001 8.78671ZM5.33341 6.66671C5.33341 5.95946 5.61437 5.28119 6.11446 4.78109C6.61456 4.28099 7.29284 4.00004 8.00008 4.00004C8.70733 4.00004 9.3856 4.28099 9.8857 4.78109C10.3858 5.28119 10.6667 5.95946 10.6667 6.66671V8.66671H5.33341V6.66671ZM8.00008 13.3334C7.7674 13.332 7.53913 13.2697 7.33797 13.1527C7.13681 13.0358 6.96976 12.8682 6.85341 12.6667H9.14675C9.03041 12.8682 8.86336 13.0358 8.66219 13.1527C8.46103 13.2697 8.23277 13.332 8.00008 13.3334ZM12.0001 11.3334H4.00008V10.6667C4.00008 10.4899 4.07032 10.3203 4.19534 10.1953C4.32037 10.0703 4.48994 10 4.66675 10H11.3334C11.5102 10 11.6798 10.0703 11.8048 10.1953C11.9298 10.3203 12.0001 10.4899 12.0001 10.6667V11.3334Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NotificationsSvg;
