import React from 'react';
import './index.scss';

interface SectionButtonLinkProps {
  className?: string;
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const SectionButtonLink = (props: SectionButtonLinkProps) => {
  const { className, children, onClick } = props;
  return (
    <button
      className={`nav-sub-section-button-link ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SectionButtonLink;
