import React from 'react';
import Link from 'components/link';
import {
  Flex,
  Text,
} from '@opsdti-global-component-library/amgen-design-system';
import { NavSection } from 'types';
import './index.scss';

const sectionTitleStyles = {
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '14px',
  color : 'rgba(0, 0, 0, 0.45)'
};
const hairlineStyles = {
  margin: 0,
  border: 0,
  outline: 0,
  height: 1,
  width: '100%',
  background: 'rgba(0,0,0, 0.06)',
};

type Props = {
  section: NavSection;
  handleClose: () => void;
};

const InternalSection = ({ handleClose, section }: Props) => {
  // uppercase for labels
  const label = section?.sectionLabel.toUpperCase();

  return (
    <Flex flex="1 1" vertical>
      <Flex vertical gap={14}>
        <Text style={sectionTitleStyles} strong>
          {label}
        </Text>
        <hr style={hairlineStyles} />
        {section.links.map((link, lidx: number) => (
          <Flex key={lidx}>
            <Link to={link.url as string} >
              <span onClick={handleClose} className={window.location.pathname === "/intelligence" && link.url === "/intelligence" ? 'currentpage-active' : window.location.pathname.includes(link?.url ? link?.url : '') && link.url !== "/intelligence" ? 'currentpage-active' : 'link-style'
              }>{link.label}</span>
            </Link>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default InternalSection;
