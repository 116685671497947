type DecodedJWTPayload = {
  ver: number;
  jti: string;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  cid: string;
  uid: string;
  scp: string[];
  auth_time: number;
  sub: string;
};

type DecodedJWTHeader = {
  kid: string;
  alg: 'RS256';
};

interface DecodedJWT {
  header: DecodedJWTHeader;
  payload: DecodedJWTPayload;
}

function decodeJWT(token: string): DecodedJWT {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid JWT token');
  }

  const header = JSON.parse(window.atob(base64UrlToBase64(parts[0])));
  const payload = JSON.parse(window.atob(base64UrlToBase64(parts[1])));

  return { header, payload };
}
function base64UrlToBase64(input: string): string {
  let base64String = input.replace(/-/g, '+').replace(/_/g, '/');
  const padLength = 4 - (base64String.length % 4);
  if (padLength < 4) {
    base64String += '='.repeat(padLength);
  }
  return base64String;
}

export const getOktaDetails = () => {
  const oktaToken = localStorage.getItem('sensing-okta-token');
  if (!oktaToken) {
    console.error('No token found in local storage');
  } else {
    const oktaTokenObj = JSON.parse(oktaToken);
    if (oktaTokenObj && oktaTokenObj.accessToken !== undefined) {
      return oktaTokenObj;
    }
  }
};

export const getUsernameFromToken = () => {
  const token = getOktaDetails();
  const decodedToken = decodeJWT(token.accessToken.accessToken);
  const userEmail = decodedToken.payload.sub;
  const username = userEmail.split('@')[0];
  return username;
};
