import { tss } from '@opsdti-global-component-library/amgen-design-system';

export const useStyles = tss
  .withParams<{
    hoverBgColor?: string;
    hoverTextColor?: string;
  }>()
  .create(({ hoverBgColor, hoverTextColor }) => ({
    mobileLeftNav: {
      '&&.ds-drawer-content': {
        '> .ds-drawer-body': {
          '> .ds-menu.ds-menu-root': {
            borderInlineEnd: 0,
            '> .ds-menu-item-active': {
              background: 'transparent',
              '.ds-menu-submenu-title': {
                background: hoverBgColor,
                color: hoverTextColor,
              },
            },
          },
        },
      },
    },
  }));
