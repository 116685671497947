import { NavLinkSections } from 'types';
import {
  Site,
  GetNavLinkSectionsRequest,
  GetNavLinkSectionsResponse,
  GetNavLinkSectionsResponseData,
  SetNavLinkSectionsRequest,
  SetNavLinkSectionsResponse,
  SetNavLinkSectionsResponseData,
} from './actions';

export class NavManager {
  constructor(site: Site) {
    this.site = site;
  }

  site: Site;

  getNavLinkSections(): Promise<GetNavLinkSectionsResponseData> {
    return new Promise(resolve => {
      const abortController = new AbortController();
      const messageId = Math.random();

      window.addEventListener(
        'message',
        (event: MessageEvent<GetNavLinkSectionsResponse>) => {
          if (
            event.origin !== window.location.origin ||
            event.data.messageId !== messageId ||
            event.data.action !== 'getNavLinkSectionsResponse'
          ) {
            return;
          }

          //remove event listener once we've receieved our message
          abortController.abort();

          resolve(event.data.data);
        },
        { signal: abortController.signal },
      );

      const action: GetNavLinkSectionsRequest = {
        site: this.site,
        messageId: messageId,
        action: 'getNavLinkSections',
      };

      window.postMessage(action, window.location.origin);
    });
  }

  setNavLinkSections(
    navLinkSections: NavLinkSections,
  ): Promise<SetNavLinkSectionsResponseData> {
    return new Promise(resolve => {
      const abortController = new AbortController();
      const messageId = Math.random();

      window.addEventListener(
        'message',
        (event: MessageEvent<SetNavLinkSectionsResponse>) => {
          if (
            event.origin !== window.location.origin ||
            event.data.messageId !== messageId ||
            event.data.action !== 'setNavLinkSectionsResponse'
          ) {
            return;
          }

          //remove event listener once we've receieved our message
          abortController.abort();

          resolve(event.data.data);
        },
        { signal: abortController.signal },
      );

      const action: SetNavLinkSectionsRequest = {
        site: this.site,
        messageId: messageId,
        action: 'setNavLinkSections',
        parameters: navLinkSections,
      };

      window.postMessage(action, window.location.origin);
    });
  }
}
