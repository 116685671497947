import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MainNav } from 'main-nav';
import { Permission } from 'types';
import '@gitlab-rtsensing/component-library/main.min.css';
import { ThemeProvider } from '@opsdti-global-component-library/amgen-design-system';

interface AppProps {
  themeService: unknown;
  permissions: Permission;
  // eslint-disable-next-line
}

function App(props: AppProps) {
  const { permissions, themeService } = props;

  const router = createBrowserRouter([
    {
      path: '/*',
      element: (
        <ThemeProvider themeService={themeService}>
          <MainNav permissions={permissions} />{' '}
        </ThemeProvider>
      ),
      children: [],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
