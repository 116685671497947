import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Radar from './radar';
import Text from './text';

interface TransitionRadarProps {
  endTransitionHandler: (miliseconds: number) => void;
}

const TransitionRadar = (props: TransitionRadarProps) => {
  const contentRoot = document.getElementById('sensing-main-content');
  const [waterRunning, setWaterRunning] = useState(true);

  const endWaterfall = async () => {
    setTimeout(() => {
      setWaterRunning(false);
      props.endTransitionHandler(500);
    }, 500);
  };

  if (!contentRoot) {
    return null;
  }

  const containerClasses = [
    'rtsensing-splash-container',
    waterRunning ? 'active' : '',
  ].join(' ');

  const splashClasses = ['rtsensing-splash', waterRunning ? 'active' : ''].join(
    ' ',
  );

  console.log('waterRunning', waterRunning);

  return createPortal(
    <CSSTransition
      in={waterRunning}
      delay={250}
      timeout={1050}
      onEntered={endWaterfall}
      unmountOnExit
      appear
    >
      <div className={containerClasses}>
        <div className={splashClasses}>
          <Radar />
          <Text />
        </div>
      </div>
    </CSSTransition>,
    contentRoot,
  );
};

export default TransitionRadar;
