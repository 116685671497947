import React from 'react';
import './styles.scss';

const Text = () => {
  return (
    <svg
      className="rtsensing-splash-text"
      width="320"
      height="108"
      viewBox="0 0 320 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M257.652 62.3809C260.48 62.3809 262.941 63.0404 265.034 64.3596C267.128 65.6787 268.634 67.346 269.552 69.3614V62.8206H273.409V93.6008C273.409 96.349 272.803 98.7858 271.591 100.911C270.415 103.036 268.762 104.667 266.632 105.803C264.502 106.975 262.078 107.562 259.36 107.562C255.503 107.562 252.289 106.646 249.718 104.814C247.184 102.981 245.604 100.49 244.98 97.3384H248.781C249.443 99.427 250.691 101.094 252.528 102.34C254.401 103.586 256.678 104.209 259.36 104.209C261.306 104.209 263.051 103.788 264.594 102.945C266.136 102.139 267.348 100.929 268.23 99.3171C269.111 97.7048 269.552 95.7994 269.552 93.6008V86.1805C268.597 88.2326 267.073 89.9365 264.979 91.2923C262.922 92.6114 260.48 93.271 257.652 93.271C254.897 93.271 252.418 92.6297 250.214 91.3472C248.047 90.0647 246.339 88.2509 245.09 85.9057C243.878 83.5606 243.272 80.849 243.272 77.771C243.272 74.6929 243.878 71.9997 245.09 69.6912C246.339 67.346 248.047 65.5505 250.214 64.3046C252.418 63.0221 254.897 62.3809 257.652 62.3809ZM269.552 77.8259C269.552 75.4075 269.056 73.2822 268.065 71.45C267.073 69.6179 265.714 68.2071 263.988 67.2177C262.298 66.2284 260.425 65.7337 258.368 65.7337C256.238 65.7337 254.328 66.2101 252.638 67.1628C250.948 68.1155 249.608 69.5079 248.616 71.3401C247.661 73.1356 247.184 75.2792 247.184 77.771C247.184 80.226 247.661 82.388 248.616 84.2568C249.608 86.0889 250.948 87.4997 252.638 88.4891C254.328 89.4418 256.238 89.9181 258.368 89.9181C260.425 89.9181 262.298 89.4235 263.988 88.4341C265.714 87.4447 267.073 86.034 268.065 84.2018C269.056 82.3697 269.552 80.2444 269.552 77.8259Z"
        fill="#0063C3"
      />
      <path
        d="M227.579 62.2695C231.179 62.2695 234.117 63.3871 236.394 65.6224C238.672 67.821 239.81 71.0272 239.81 75.2412V92.8299H236.009V75.6809C236.009 72.4197 235.182 69.9279 233.53 68.2057C231.913 66.4835 229.691 65.6224 226.863 65.6224C223.961 65.6224 221.647 66.5385 219.921 68.3706C218.195 70.2028 217.332 72.896 217.332 76.4504V92.8299H213.475V62.8192H217.332V67.9309C218.287 66.0987 219.664 64.7063 221.464 63.7536C223.264 62.7642 225.302 62.2695 227.579 62.2695Z"
        fill="#0063C3"
      />
      <path
        d="M206.118 59.5959C205.347 59.5959 204.686 59.3211 204.135 58.7715C203.584 58.2218 203.309 57.5439 203.309 56.7378C203.309 55.9316 203.584 55.272 204.135 54.759C204.686 54.2094 205.347 53.9346 206.118 53.9346C206.89 53.9346 207.551 54.2094 208.102 54.759C208.653 55.272 208.928 55.9316 208.928 56.7378C208.928 57.5439 208.653 58.2218 208.102 58.7715C207.551 59.3211 206.89 59.5959 206.118 59.5959ZM208.047 62.8197V92.8304H204.19V62.8197H208.047Z"
        fill="#0063C3"
      />
      <path
        d="M189.77 93.271C186.318 93.271 183.489 92.4832 181.286 90.9075C179.119 89.2952 177.907 87.1149 177.649 84.3667H181.616C181.8 86.0523 182.59 87.4264 183.985 88.4891C185.418 89.5151 187.328 90.0281 189.715 90.0281C191.809 90.0281 193.443 89.5334 194.619 88.544C195.831 87.5547 196.437 86.3271 196.437 84.8614C196.437 83.8354 196.106 82.9926 195.445 82.333C194.784 81.6734 193.939 81.1604 192.911 80.794C191.919 80.3909 190.56 79.9695 188.834 79.5298C186.593 78.9435 184.775 78.3572 183.379 77.771C181.984 77.1847 180.79 76.3236 179.798 75.1876C178.843 74.015 178.366 72.4577 178.366 70.5156C178.366 69.0499 178.806 67.6941 179.688 66.4482C180.569 65.2024 181.818 64.213 183.434 63.4802C185.05 62.7473 186.887 62.3809 188.944 62.3809C192.176 62.3809 194.784 63.2053 196.767 64.8543C198.751 66.4666 199.816 68.7018 199.963 71.56H196.106C195.996 69.8011 195.298 68.3903 194.012 67.3277C192.764 66.2284 191.037 65.6787 188.834 65.6787C186.887 65.6787 185.308 66.1368 184.095 67.0529C182.883 67.9689 182.277 69.1049 182.277 70.4607C182.277 71.6332 182.626 72.6043 183.324 73.3738C184.059 74.1066 184.959 74.6929 186.024 75.1327C187.089 75.5357 188.521 75.9938 190.321 76.5068C192.488 77.0931 194.215 77.661 195.5 78.2107C196.786 78.7603 197.887 79.5665 198.806 80.6291C199.724 81.6918 200.201 83.1025 200.238 84.8614C200.238 86.4737 199.797 87.9211 198.916 89.2036C198.034 90.4495 196.804 91.4388 195.225 92.1717C193.645 92.9046 191.827 93.271 189.77 93.271Z"
        fill="#0063C3"
      />
      <path
        d="M161.958 62.2695C165.558 62.2695 168.496 63.3871 170.773 65.6224C173.051 67.821 174.189 71.0272 174.189 75.2412V92.8299H170.388V75.6809C170.388 72.4197 169.561 69.9279 167.908 68.2057C166.292 66.4835 164.07 65.6224 161.242 65.6224C158.34 65.6224 156.026 66.5385 154.3 68.3706C152.574 70.2028 151.711 72.896 151.711 76.4504V92.8299H147.854V62.8192H151.711V67.9309C152.666 66.0987 154.043 64.7063 155.843 63.7536C157.642 62.7642 159.681 62.2695 161.958 62.2695Z"
        fill="#0063C3"
      />
      <path
        d="M144.103 76.2869C144.103 77.6061 144.067 78.6137 143.993 79.31H119.035C119.145 81.5818 119.696 83.5239 120.688 85.1362C121.68 86.7485 122.984 87.9761 124.6 88.8188C126.216 89.625 127.979 90.0281 129.889 90.0281C132.386 90.0281 134.48 89.4235 136.17 88.2142C137.896 87.005 139.035 85.3744 139.586 83.3224H143.663C142.928 86.2538 141.349 88.654 138.924 90.5228C136.537 92.3549 133.525 93.271 129.889 93.271C127.061 93.271 124.526 92.6481 122.286 91.4022C120.045 90.1197 118.282 88.3242 116.997 86.0157C115.748 83.6705 115.124 80.9406 115.124 77.8259C115.124 74.7113 115.748 71.9813 116.997 69.6362C118.246 67.291 119.99 65.4955 122.231 64.2497C124.471 63.0038 127.024 62.3809 129.889 62.3809C132.754 62.3809 135.251 63.0038 137.382 64.2497C139.549 65.4955 141.202 67.1811 142.34 69.3064C143.516 71.3951 144.103 73.7219 144.103 76.2869ZM140.192 76.177C140.228 73.9418 139.769 72.0363 138.814 70.4607C137.896 68.885 136.629 67.6941 135.013 66.888C133.397 66.0818 131.634 65.6787 129.724 65.6787C126.859 65.6787 124.416 66.5948 122.396 68.427C120.376 70.2591 119.256 72.8425 119.035 76.177H140.192Z"
        fill="#0063C3"
      />
      <path
        d="M100.344 93.2164C97.8093 93.2164 95.5688 92.7767 93.6221 91.8973C91.7121 91.0178 90.2062 89.7903 89.1043 88.2147C88.0024 86.639 87.4148 84.8801 87.3413 82.938H91.4183C91.602 84.8068 92.4284 86.4558 93.8976 87.8849C95.3667 89.2773 97.5154 89.9735 100.344 89.9735C102.951 89.9735 105.008 89.3139 106.514 87.9948C108.057 86.639 108.828 84.9351 108.828 82.8831C108.828 81.2341 108.406 79.915 107.561 78.9256C106.716 77.8996 105.669 77.1484 104.421 76.6721C103.172 76.1591 101.446 75.6094 99.2417 75.0231C96.6707 74.3269 94.6322 73.649 93.1262 72.9894C91.6203 72.3299 90.3348 71.3039 89.2696 69.9114C88.2045 68.519 87.6719 66.6319 87.6719 64.2501C87.6719 62.2713 88.1861 60.5125 89.2145 58.9735C90.243 57.3978 91.6938 56.1703 93.567 55.2908C95.4402 54.4114 97.5889 53.9717 100.013 53.9717C103.576 53.9717 106.441 54.8511 108.608 56.61C110.812 58.3322 112.079 60.5674 112.409 63.3157H108.222C107.965 61.74 107.102 60.3476 105.633 59.1384C104.164 57.8925 102.18 57.2696 99.6825 57.2696C97.3685 57.2696 95.4402 57.8925 93.8976 59.1384C92.3549 60.3476 91.5836 62.0148 91.5836 64.1401C91.5836 65.7524 92.006 67.0533 92.8508 68.0426C93.6955 69.032 94.7423 69.7832 95.9912 70.2962C97.2767 70.8092 99.003 71.3588 101.17 71.9451C103.668 72.6413 105.688 73.3376 107.23 74.0338C108.773 74.6933 110.077 75.7194 111.142 77.1118C112.207 78.5042 112.74 80.373 112.74 82.7182C112.74 84.5137 112.262 86.2176 111.307 87.8299C110.352 89.4422 108.938 90.743 107.065 91.7324C105.192 92.7218 102.951 93.2164 100.344 93.2164Z"
        fill="#0063C3"
      />
      <path
        d="M169.736 24.8099C168.041 22.6111 165.837 21.258 162.954 21.258C157.868 21.258 153.799 25.3173 153.799 30.3915C153.799 35.4657 157.868 39.525 162.954 39.525C166.515 39.525 169.736 37.3262 171.092 34.2817H161.768V26.8396H178.891V15.1689H194.15V22.6111H186.181V26.6704H194.15V34.1126H186.181V38.0028H194.15V45.2758C194.489 45.2758 178.891 45.2758 178.891 45.2758V34.1126C177.026 42.4004 170.414 46.6289 162.954 46.6289C155.495 46.6289 149.391 42.2313 147.526 34.4508V36.4805V45.1066H140.067V27.6853C140.067 27.6853 135.659 31.5755 135.659 45.4449H128.029C128.029 31.5755 123.621 27.6853 123.621 27.6853V45.4449H116.162V14.6615C124.808 14.6615 130.572 24.3025 131.759 30.0532C133.116 24.3025 138.88 14.6615 147.357 14.6615V24.8099C148.883 18.0443 155.664 13.4775 162.785 13.4775C170.075 13.4775 176.518 18.89 177.704 24.8099H169.736Z"
        fill="#0063C3"
      />
      <path
        d="M210.599 45.4527C209.582 37.1649 203.987 31.0759 203.987 31.0759V45.2836H196.019V15.1768C197.714 15.1768 204.156 18.5595 210.599 27.8622V15.1768H218.398V45.4527H210.599Z"
        fill="#0063C3"
      />
      <path
        d="M97.1746 37.8331C97.0051 38.6788 95.6487 43.4147 95.1401 45.4443H87.3413C88.3585 41.5541 90.393 35.1268 92.4275 30.7292C94.462 26.3316 97.1746 21.5957 101.413 18.382C103.448 16.8598 107.177 13.9844 114.298 14.6609V45.4443H106.838V37.8331H97.1746ZM106.838 30.7292V23.6253C104.465 25.3167 101.922 28.3612 100.226 30.7292H106.838Z"
        fill="#0063C3"
      />
      <path
        d="M280.707 68.0645C280.707 64.9784 283.214 62.4766 286.308 62.4766H314.304C317.397 62.4766 319.905 64.9784 319.905 68.0645V87.7131C319.905 90.7992 317.397 93.301 314.304 93.301H286.308C283.214 93.301 280.707 90.7992 280.707 87.7131V68.0645Z"
        fill="url(#paint0_linear_11838_61334)"
      />
      <path
        d="M287.517 83.9341V82.0768L291.867 78.0585C292.237 77.7013 292.548 77.3798 292.798 77.0941C293.053 76.8083 293.246 76.5285 293.377 76.2547C293.508 75.9769 293.574 75.6772 293.574 75.3558C293.574 74.9986 293.492 74.691 293.329 74.4331C293.166 74.1711 292.943 73.9707 292.661 73.8318C292.379 73.6889 292.058 73.6175 291.7 73.6175C291.326 73.6175 291 73.6929 290.722 73.8437C290.443 73.9945 290.228 74.2108 290.077 74.4926C289.926 74.7744 289.851 75.1097 289.851 75.4986H287.398C287.398 74.7009 287.579 74.0084 287.941 73.421C288.303 72.8337 288.81 72.3792 289.463 72.0578C290.115 71.7363 290.867 71.5756 291.718 71.5756C292.593 71.5756 293.355 71.7304 294.004 72.0399C294.656 72.3455 295.163 72.7702 295.525 73.3139C295.887 73.8576 296.068 74.4807 296.068 75.1831C296.068 75.6435 295.977 76.0979 295.794 76.5464C295.615 76.9949 295.295 77.4929 294.833 78.0406C294.372 78.5843 293.721 79.2372 292.882 79.9992L291.098 81.7434V81.8267H296.229V83.9341H287.517Z"
        fill="white"
      />
      <path
        d="M300.105 84.0889C299.711 84.0889 299.373 83.95 299.091 83.6722C298.812 83.3904 298.673 83.0531 298.673 82.6602C298.673 82.2712 298.812 81.9379 299.091 81.6601C299.373 81.3823 299.711 81.2433 300.105 81.2433C300.487 81.2433 300.821 81.3823 301.108 81.6601C301.394 81.9379 301.537 82.2712 301.537 82.6602C301.537 82.9221 301.47 83.1622 301.334 83.3805C301.203 83.5948 301.03 83.7674 300.815 83.8984C300.6 84.0254 300.364 84.0889 300.105 84.0889Z"
        fill="white"
      />
      <path
        d="M308.278 84.202C307.252 84.1981 306.369 83.946 305.629 83.446C304.893 82.9459 304.326 82.2216 303.928 81.2731C303.535 80.3246 303.34 79.1836 303.344 77.8501C303.344 76.5206 303.541 75.3875 303.934 74.4509C304.332 73.5143 304.899 72.8019 305.635 72.3138C306.375 71.8216 307.256 71.5756 308.278 71.5756C309.301 71.5756 310.18 71.8216 310.916 72.3138C311.656 72.8059 312.225 73.5202 312.623 74.4569C313.02 75.3895 313.217 76.5206 313.213 77.8501C313.213 79.1876 313.014 80.3305 312.617 81.2791C312.223 82.2276 311.658 82.9519 310.922 83.4519C310.186 83.952 309.305 84.202 308.278 84.202ZM308.278 82.0649C308.979 82.0649 309.538 81.7136 309.955 81.0112C310.373 80.3087 310.58 79.255 310.576 77.8501C310.576 76.9254 310.48 76.1555 310.289 75.5403C310.102 74.9252 309.836 74.4628 309.49 74.1533C309.148 73.8437 308.744 73.6889 308.278 73.6889C307.582 73.6889 307.025 74.0362 306.608 74.7307C306.19 75.4252 305.979 76.465 305.975 77.8501C305.975 78.7867 306.069 79.5686 306.256 80.1956C306.447 80.8187 306.715 81.287 307.061 81.6005C307.407 81.9101 307.813 82.0649 308.278 82.0649Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11838_61334"
          x1="300.306"
          y1="62.4766"
          x2="300.306"
          y2="93.301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0063C3" />
          <stop offset="1" stopColor="#469BDB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Text;
