import React from 'react';

interface SvgProps {
  className?: string;
}

const PrioritizedAgendaSvg = (props: SvgProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0002 1.33337H2.00016C1.82335 1.33337 1.65378 1.40361 1.52876 1.52864C1.40373 1.65366 1.3335 1.82323 1.3335 2.00004V14C1.3335 14.1769 1.40373 14.3464 1.52876 14.4714C1.65378 14.5965 1.82335 14.6667 2.00016 14.6667H14.0002C14.177 14.6667 14.3465 14.5965 14.4716 14.4714C14.5966 14.3464 14.6668 14.1769 14.6668 14V2.00004C14.6668 1.82323 14.5966 1.65366 14.4716 1.52864C14.3465 1.40361 14.177 1.33337 14.0002 1.33337ZM5.3335 13.3334H2.66683V2.66671H5.3335V13.3334ZM9.3335 13.3334H6.66683V2.66671H9.3335V13.3334ZM13.3335 13.3334H10.6668V2.66671H13.3335V13.3334Z"
        fill="currentColor"
      />
      <path
        d="M14.6668 14V2.00004C14.6668 1.82323 14.5966 1.65366 14.4716 1.52864C14.3465 1.40361 14.177 1.33337 14.0002 1.33337H2.00016C1.82335 1.33337 1.65378 1.40361 1.52876 1.52864C1.40373 1.65366 1.3335 1.82323 1.3335 2.00004V14C1.3335 14.1769 1.40373 14.3464 1.52876 14.4714C1.65378 14.5965 1.82335 14.6667 2.00016 14.6667H14.0002C14.177 14.6667 14.3465 14.5965 14.4716 14.4714C14.5966 14.3464 14.6668 14.1769 14.6668 14ZM2.66683 5.33337L2.66683 2.66671H13.3335L13.3335 5.33337L2.66683 5.33337ZM2.66683 9.33337L2.66683 6.66671L13.3335 6.66671V9.33337L2.66683 9.33337ZM2.66683 13.3334L2.66683 10.6667L13.3335 10.6667L13.3335 13.3334H2.66683Z"
        fill="currentColor"
      />
      <path d="M6.66683 2.6602H9.3335V5.32686H6.66683V2.6602Z" fill="currentColor" />
      <path d="M10.6668 2.6602H13.3335V5.32686H10.6668V2.6602Z" fill="currentColor" />
      <path d="M10.6668 6.6602H13.3335V9.32686H10.6668V6.6602Z" fill="currentColor" />
    </svg>
  );
};

export default PrioritizedAgendaSvg;
