import React from 'react';
import './index.scss';

interface NavMenuButtonProps {
  onClick: () => void;
  isActive: boolean;
}

const NavMenuButton = (props: NavMenuButtonProps) => {
  const { onClick, isActive } = props;

  return (
    <button onClick={onClick} className={`nav-menu-button`}>
      <div className={`nav-icon-block  ${isActive ? 'menu-active' : ''}`}>
        <div className={`nav-menu-icon ${isActive ? 'menu-active' : ''}`}>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
    </button>
  );
};

export default NavMenuButton;
