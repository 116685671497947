import React from 'react';

type SvgProps = {
  className?: string;
};

const HomeIcon = (props: SvgProps) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="home-icon"
      {...props}
    >
      <path
        d="M21.6601 10.25L12.6601 2.24996C12.4776 2.08964 12.243 2.00122 12.0001 2.00122C11.7572 2.00122 11.5226 2.08964 11.3401 2.24996L2.34012 10.25C2.18715 10.3853 2.07929 10.5642 2.03102 10.7626C1.98275 10.961 1.99639 11.1695 2.07012 11.36C2.14264 11.5479 2.27022 11.7095 2.43617 11.8237C2.60211 11.9379 2.79868 11.9994 3.00012 12H4.00012V21C4.00012 21.2652 4.10548 21.5195 4.29301 21.7071C4.48055 21.8946 4.7349 22 5.00012 22H19.0001C19.2653 22 19.5197 21.8946 19.7072 21.7071C19.8948 21.5195 20.0001 21.2652 20.0001 21V12H21.0001C21.2016 11.9994 21.3981 11.9379 21.5641 11.8237C21.73 11.7095 21.8576 11.5479 21.9301 11.36C22.0038 11.1695 22.0175 10.961 21.9692 10.7626C21.9209 10.5642 21.8131 10.3853 21.6601 10.25ZM13.0001 20H11.0001V17C11.0001 16.7347 11.1055 16.4804 11.293 16.2928C11.4805 16.1053 11.7349 16 12.0001 16C12.2653 16 12.5197 16.1053 12.7072 16.2928C12.8948 16.4804 13.0001 16.7347 13.0001 17V20ZM18.0001 20H15.0001V17C15.0001 16.2043 14.684 15.4412 14.1214 14.8786C13.5588 14.316 12.7958 14 12.0001 14C11.2045 14 10.4414 14.316 9.8788 14.8786C9.31619 15.4412 9.00012 16.2043 9.00012 17V20H6.00012V12H18.0001V20ZM5.63012 9.99996L12.0001 4.33996L18.3701 9.99996H5.63012Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HomeIcon;
