import React from 'react';
import { MenuIcon } from '@opsdti-global-component-library/amgen-design-system';
import { useStyles } from './styles';

interface MobileNavButtonProps {
  handleSetMenuActive: () => void;
}

const MobileNavButton = ({ handleSetMenuActive }: MobileNavButtonProps) => {
  const { classes } = useStyles();
  return (
    <button className={classes.mobileNavButton} onClick={handleSetMenuActive}>
      <MenuIcon color="white" />
    </button>
  );
};

export default MobileNavButton;
