import { Modal } from '@gitlab-rtsensing/component-library';
import React from 'react';
import './index.scss';

interface FeedbackModalProps {
  title?: string;
  opened: boolean;
  onFeedbackModalClosed: () => void;
  feedbackSource: string;
}

const FeedbackModal = (props: FeedbackModalProps) => {
  const { title = '', opened, onFeedbackModalClosed, feedbackSource } = props;
  return (
    <Modal
      className="feedback-modal"
      opened={opened}
      onClose={onFeedbackModalClosed}
    >
      <Modal.Header contentClassName="d-flex align-items-center">
        <Modal.Title title={title} />
      </Modal.Header>
      <div className="feedback-iframe-wrapper">
        <div>
          <iframe
            seamless={true}
            title="Feedback Form"
            width="100%"
            height="100%"
            src={feedbackSource}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
