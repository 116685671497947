import React from 'react';
import { NavSection } from 'types';
import './index.scss';
import { UPDATE_SIDE_NAV_WORKSTREAMS } from 'desktop-side-nav';

interface SectionTitleProps {
  color?: string;
  section: NavSection;
  pageName?: string;
}

const SectionTitle = (props: SectionTitleProps) => {
  const { color, section, pageName } = props;
  return (
    <p
      className={
        UPDATE_SIDE_NAV_WORKSTREAMS.includes(pageName!.toUpperCase())
          ? 'nav-sub-section-title intelligence-section-title'
          : 'nav-sub-section-title'
      }
      style={{ color: color }}
    >
      {section.sectionLabel.toUpperCase()}
    </p>
  );
};

export default SectionTitle;
