import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useWindowSize } from 'usehooks-ts';
import { Link, useLocation, Location } from 'react-router-dom';
import Logo from '../components/svgs/sensing-logo-svg';
import { DesktopSideNav } from 'desktop-side-nav';
import { links as initialLinks } from 'mocks/links';
import { NavLink, NavSection, Permission, megaMenuKeys } from 'types';
import MobileNavButton from 'mobile-nav-button';
import MobileLeftNav from 'mobile-left-nav';
import FeedbackModal from 'components/feedback-modal';
import TransitionRadar from 'components/transition-radar';
import Footer from 'footer';
import './index.scss';
import useNavManager from 'nav-manager/useNavManager';
import { caseInsensitiveMatch, getEnv } from 'utils/';
import {
  Flex,
  Text,
  ThemeProvider,
  breakpoints,
} from '@opsdti-global-component-library/amgen-design-system';
import MegaMenu from 'mega-menu';
import MegaMenuContents from 'mega-menu-contents';
import { MegaMenuManager, IMegaMenuSection } from 'mega-menu-manager';
import getIcon from 'utils/get-icon';

export type Contents = { [key: string]: IMegaMenuSection[] };

const getRootRoute = (location: Location) => {
  return `/${location.pathname.split('/')[1]}`;
};
interface MatamoWindow extends Window {
  _paq?: Array<Array<string | number>>;
}

interface MainNavProps {
  themeService?: unknown;
  permissions: Permission;
}

const ENV = getEnv();

export const MainNav = (props: MainNavProps) => {
  const { permissions } = props;

  const megaMenuManager = useMemo(
    () => new MegaMenuManager(permissions),
    [permissions],
  );

  const location = useLocation();
  const { links, metaData } = useNavManager(initialLinks);
  const [hideMainNavBar, setHideMainNavBar] = useState(false);
  const [hideSideNavBar, setHideSideNavBar] = useState(false);
  const [authorizedLinks, setAuthorizedLinks] = useState<NavLink[]>([]);
  const [startTransition, setStartTransition] = useState(false);
  const [feedbackOpened, setFeedbackOpened] = useState(false);
  const [feedbackSource, setFeedbackSource] = useState('');
  const [currentRootRoute, setCurrentRootRoute] = useState(() =>
    getRootRoute(location),
  );
  const trackPageView = useCallback((location: Location) => {
    const matomoWindow: MatamoWindow = window;
    let pageName = location.pathname.substring(1);
    const scripts = document.getElementsByTagName('script');

    /**
     * Before tracking a new pageView, remove the current
     * heatmapSessionRecording script to prevent future duplication
     * of the script when a new pageView is tracked
     *  */
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].src.includes('HeatmapSessionRecording')) {
        scripts[i].remove();
      }
    }

    if (matomoWindow._paq) {
      pageName = pageName ? pageName.toUpperCase() : 'HOME';
      matomoWindow._paq.push(['setCustomUrl', location.pathname]);
      matomoWindow._paq.push(['setDocumentTitle', pageName]);
      matomoWindow._paq.push(['trackPageView']);
    }
  }, []);

  const invokeMatomoHeartbeat = useCallback(() => {
    const matomoWindow: MatamoWindow = window;

    if (matomoWindow._paq) {
      matomoWindow._paq.push(['enableHeartBeatTimer', 15]);
    }
  }, []);

  /**
   * Initial Matomo Page View
   * Upon loading the Nav and setting the initial route, we must enable the heartbeat
   * timer to enforce accurate capturing of a users time on page
   * https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page
   */
  useEffect(() => {
    invokeMatomoHeartbeat();
  }, [invokeMatomoHeartbeat]);

  useEffect(() => {
    const authLinks = links.map((link: NavLink) => {
      const authLink = { ...link };
      const linkKey = link.id ?? '';
      const linkIsAuth = permissions[linkKey];
      authLink.authorized = linkIsAuth;

      if (linkKey === 'admin') {
        adminRealTimeUsage(authLink);
      }

      if (
        linkKey === 'home' &&
        authLink.internalSections &&
        authLink.internalSections[0].links
      ) {
        authLink.internalSections = [...authLink.internalSections];
        authLink.internalSections[0] = { ...authLink.internalSections[0] };
        authLink.internalSections[0].links = [
          ...authLink.internalSections[0].links,
        ];
        authLink.internalSections[0].links =
          authLink.internalSections[0].links.map((intLink: NavLink) => {
            const authSubLink = { ...intLink };
            const intLinkKey = intLink.id ?? '';
            const linkIsAuth = !!permissions[intLinkKey];
            authSubLink.authorized = linkIsAuth;
            return authSubLink;
          });
      }
      const icon = getIcon(authLink.id);
      return { ...authLink, icon };
    });
    setAuthorizedLinks(authLinks);
  }, [links, permissions]);

  useEffect(() => {
    const routeChange = getRootRoute(location);
    const routeChanged = routeChange !== currentRootRoute;

    if (routeChanged) {
      setStartTransition(true);
    }

    if (routeChanged) {
      trackPageView(location);
    }

    setCurrentRootRoute(getRootRoute(location));
  }, [location, currentRootRoute, trackPageView]);

  const matchLink = useCallback(
    (checkedURL: string | undefined) => {
      let isMatched = false;
      if (checkedURL === '/home') {
        isMatched =
          caseInsensitiveMatch(checkedURL, currentRootRoute) ||
          '/' === currentRootRoute;
      } else if (checkedURL) {
        isMatched = caseInsensitiveMatch(checkedURL, currentRootRoute);
      }

      return isMatched;
    },
    [currentRootRoute],
  );

  const [megaMenuContents, setMegaMenuContents] = useState<
    undefined | Contents
  >(undefined);
  const [dropdownIds, setDropdownIds] = useState<[] | string[]>([]);
  const [currentMegaMenu, setCurrentMegaMenu] = useState<undefined | string>(
    undefined,
  );

  useEffect(() => {
    const menuSections = megaMenuManager.generateMenuSections();
    menuSections
      .then(sections => {
        setMegaMenuContents(sections);
        setDropdownIds(Object.keys(sections));
      })
      .catch(error => {
        console.log(error);
        setMegaMenuContents(undefined);
        setDropdownIds([]);
      });
  }, [megaMenuManager]);

  const handleMenuOpen = useCallback(
    (id: string) => {
      if (megaMenuKeys.includes(id)) {
        if (id !== currentMegaMenu) {
          setCurrentMegaMenu(id);
          return;
        }
        return;
      } else setCurrentMegaMenu(undefined);
    },
    [currentMegaMenu],
  );

  const ref = useRef(null);
  const handleCloseMegaMenu = () => setCurrentMegaMenu(undefined);

  const buildLinks = () => {
    if (dropdownIds && megaMenuContents) {
      return authorizedLinks.map((linkData, index) => {
        const isActive = matchLink(linkData.url);
        const isAuth = linkData.authorized;

        const to = linkData?.url ?? '/';

        const linkClasses = ['main-nav-link', isActive && 'active'].join(' ');
        const { icon } = linkData;

        return (
          <React.Fragment key={index}>
            {isAuth && (
              <Link
                className={linkClasses}
                to={to}
                onMouseOver={() => handleMenuOpen(linkData?.id as string)}
              >
                <Flex gap={4} align="center">
                  {icon}
                  <Text strong type="secondary" className="main-nav-link-text">
                    {linkData.label}
                  </Text>
                </Flex>
              </Link>
            )}
          </React.Fragment>
        );
      });
    }
  };

  const getRouteData = () => {
    const foundData = authorizedLinks.find(item => matchLink(item.url));
    if (foundData) {
      if (foundData.authorized) {
        return foundData;
      } else {
        return { label: 'Not Authorized' } as NavLink;
      }
    } else {
      return { label: 'Not Found' } as NavLink;
    }
  };

  const openFeedbackModal = (type: 'CONTACT' | 'FEEDBACK' | 'ISSUE') => {
    const sources = {
      CONTACT:
        'https://app.smartsheet.com/b/form/71afbe95cd2f4b60af61e7535d9c6da3',
      FEEDBACK:
        'https://app.smartsheet.com/b/form/3575576ab3f4480bb6d377f8465b2b2b',
      ISSUE:
        'https://app.smartsheet.com/b/form/2507ea35bef342cda8325c4f01023cea',
    };

    const activatedSource = sources[type];

    if (activatedSource) {
      setFeedbackOpened(true);
      setFeedbackSource(activatedSource);
    }
  };

  const feedbackModalClosed = () => {
    setFeedbackOpened(false);
    setFeedbackSource('');
  };

  const endTransitionHandler = (totalTimeout: number) => {
    const timeout = totalTimeout;
    setTimeout(() => {
      setStartTransition(false);
    }, timeout);
  };

  /**
   *
   * @param authLink : Accept Navigation Link as parameter
   * @description : Use to Modify default Matomo  and grafana link according to different environment
   */
  const adminRealTimeUsage = (authLink: NavLink) => {
    //Bypass prod case, no computation required
    if (ENV !== 'prod') {
      authLink.externalSections?.map((linkObj: NavSection) => {
        const prodSiteId = '37'; //matamo siteId prod
        const urlLinkArr = linkObj.links[0].url
          ? linkObj.links[0].url.split(prodSiteId)
          : [];
        const newLink = urlLinkArr.join(ENV);
        // eslint-disable-next-line no-param-reassign
        linkObj.links[0].url =
          urlLinkArr.length === 0 ? linkObj.links[0].url : newLink;
        return newLink;
      });
      //Below change for Admin Graphana Link : Start
      if (ENV !== 'prod' && ENV !== 'stg') {
        authLink.externalSections?.map((linkObj: NavSection) => {
          const newLink =
            'https://grafana.nimbus.amgen.com/d/aa87b1ab-8f3a-4f55-8d5d-db6081b4c462/cronjobs-status-for-dev-and-test-env?orgId=1';

          // eslint-disable-next-line no-param-reassign
          linkObj.links[2].url = newLink;
          return newLink;
        });
      }
    }
  };

  function scrollIntoViewWithOffset(element: HTMLElement, offset: number) {
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          element.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          offset,
      });
    }
  }

  useEffect(() => {
    // if not a hash link, scroll to top
    if (location.hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          scrollIntoViewWithOffset(element, 60);
        }
      }, 0);
    }
  }, [location]);

  useEffect(() => {
    if (metaData && metaData.hideMainNavBar === true) {
      setHideMainNavBar(true);
    } else {
      setHideMainNavBar(false);
    }

    if (metaData && metaData.hideSideNavBar === true) {
      setHideSideNavBar(true);
    } else {
      setHideSideNavBar(false);
    }
  }, [metaData]);

  const [menuActive, setMenuActive] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width >= breakpoints.lg) setMenuActive(false);
  }, [windowSize.width, setMenuActive]);

  return (
    <ThemeProvider themeService={props.themeService}>
      <MobileLeftNav
        activeRouteData={getRouteData()}
        handleMenuActive={() => setMenuActive(menuActive => !menuActive)}
        menuActive={menuActive}
        routeData={authorizedLinks}
        currentMegaMenu={currentMegaMenu}
        megaMenuContents={megaMenuContents}
      />
      <nav className="main-nav">
        <MobileNavButton
          handleSetMenuActive={() => setMenuActive(active => !active)}
        />
        <div className="main-nav-logo-block">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div
          className={`main-nav-link-block ${
            hideMainNavBar ? 'sensing-nav-hide' : ''
          }`}
        >
          {buildLinks()}
        </div>
        <MegaMenu
          isActive={!!currentMegaMenu}
          forwardRef={ref}
          onMouseLeave={() => handleCloseMegaMenu()}
        >
          <MegaMenuContents
            {...{
              currentMegaMenu,
              data: megaMenuContents,
              handleClose: handleCloseMegaMenu,
            }}
          />
        </MegaMenu>
      </nav>
      <DesktopSideNav
        {...{
          currentMegaMenu,
          megaMenuContents,
        }}
        hideNav={hideSideNavBar}
        activeRouteData={getRouteData()}
      />
      <Footer
        activeRouteData={getRouteData()}
        toggleFeedbackModal={openFeedbackModal}
      />
      {startTransition && (
        <TransitionRadar endTransitionHandler={endTransitionHandler} />
      )}
      <FeedbackModal
        feedbackSource={feedbackSource}
        opened={feedbackOpened}
        onFeedbackModalClosed={feedbackModalClosed}
      />
    </ThemeProvider>
  );
};
